import React from 'react';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import Layout from '../components/layouts';
import { graphql } from 'gatsby';
import Hero from '../components/shared/Hero';
import DonateSection from '../components/donate/DonateSection';
import HowItsUsedSection from '../components/donate/HowItsUsedSection';
import OtherWaysSection from '../components/donate/OtherWaysSection';
import CampaignSection from '../components/donate/CampaignSection';
import Footnote from '../components/shared/Footnote';
import routes from '../routes';


class DonatePage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page('Donate', {
      title: `Donate | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const props = this.props;
    const pageData = props.data.prismicDonatePage.data;
    const rawData = JSON.parse(props.data.prismicDonatePage.dataString);

    return (
      <Layout reverseLogo={true}>
        <Helmet>
          {titleTags(`Donate | ${props.data.prismicSite.data.name}`)}
          {descriptionTags(pageData.meta_description)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <Hero
          title={'Donate'}
          message={pageData.hero_message}
          brief={pageData.hero_brief.html}
          image={pageData.hero_image}
          showFullImage={pageData.show_full_image}
          reverseGradient
        />
        <DonateSection
          rawData={rawData}
          pageData={pageData}
        />
        {rawData.campaign_visibility === 'Show' && (
          <CampaignSection
            rawData={rawData}
          />
        )}
        <HowItsUsedSection
          rawData={rawData}
          pageData={pageData}
        />
        <OtherWaysSection
          rawData={rawData}
          pageData={pageData}
        />
        <Footnote
          message={rawData.footnote_message}
          buttonText={'Speak with us'}
          to={routes.contact}
        />
      </Layout>
    );
  }
}

export default DonatePage;


export const query = graphql`
  query DonatePageQuery {
    prismicSite {
      data {
        name
      }
    }
    prismicDonatePage {
      dataString
      data {
        meta_description
        hero_message
        hero_brief {
          html
        }
        hero_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }

            }
          }
        }
        show_full_image
        donate_message
        donate_brief {
          html
          text
        }
        how_message
        how_brief {
          html
          text
        }
        how_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }

            }
          }
        }
        other_ways_title
        other_ways {
          title
          brief {
            html
            text
          }
        }
      }
    }
  }
`;
