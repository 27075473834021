import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { colors } from '../../styles/colors';
import { linkResolver } from '../../resolvers';
import { RichText } from 'prismic-reactjs';
import Footnote from '../shared/Footnote';
import routes from '../../routes';
import { rtypeN16r, rtypeT38sb } from '../../styles/typography/presets';
import { WhiteButton } from '../buttons';

const Background = styled.div`
`;

const Image = styled.div`
  position: relative;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
`;

const Title = styled.div`
  ${rtypeT38sb};
`;

const Brief = styled.div`
  ${rtypeN16r};
`;

const Button = styled(WhiteButton)`

`;

const TextBlock = styled.div`
`;

const CustomFootnote = styled(Footnote)`

`;

const CustomContainer = styled(Container)`
  grid-template-rows: auto;
  color: ${colors.white};
  
  ${Image} {
    z-index: -1;
    grid-row: 1;
    grid-column: 5 / span 6;
  }
  ${Background} {
    z-index: 0;
    grid-row: 1;
    grid-column: 1 / span 10;
    background: linear-gradient(-60deg, transparent -80%, ${colors.purple} 50%);
  }
  ${TextBlock} {
    z-index: 1;
    grid-row: 1;
    grid-column: 2 / span 8;
    margin: 60px 0;
    ${Title} {
      margin-bottom: 10px;
    }
    ${Button} {
      margin-top: 30px;
    }
  }
  ${CustomFootnote} {
    grid-row: 4 / span 1;
    grid-column: 1 / span 10;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    grid-template-rows: auto;
    ${Image} {
      grid-column: 1 / span 6;
      grid-row: 1;
    }
    ${Background} {
      grid-column: 1 / span 10;
      grid-row: 1;
      background: linear-gradient(80deg, transparent, ${colors.purple} 50%);
    }
    
    ${TextBlock} {
      grid-column: 6 / span 3;
      grid-row: 1;
      margin: 180px 0 180px;
      ${Button} {
        margin-top: 40px;
      }
    }
    
  }
  
`;

export default ({ rawData, ...props }) => (
  <CustomContainer>
    <Background/>
    <Image backgroundImage={rawData.campaign_image.url}>
      {/*<img src={rawData.campaign_image.url} alt={rawData.campaign_image.alt}/>*/}
    </Image>
    <TextBlock>
      <Title>{rawData.campaign_title}</Title>
      <Brief>{RichText.render(rawData.campaign_brief, linkResolver)}</Brief>
      {rawData.campaign_link && (
        <Button>
          <a href={rawData.campaign_link.url} target={'_blank'} rel={'noopener noreferrer'}>
            {rawData.campaign_button_text}
          </a>
        </Button>
      )}
    </TextBlock>
  </CustomContainer>
)