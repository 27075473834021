import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../resolvers';
import { rtypeN18r, rtypeT38sb } from '../../styles/typography/presets';
import DonationForm from './DonationForm';
import GradientImg from '../../assets/images/gradient2.png';


const Message = styled.div`
  ${rtypeT38sb};
`;

const Brief = styled.div`
  ${rtypeN18r};
  position: relative;
  z-index: 2;
  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const TextBlock = styled.div`
`;

const FormBlock = styled.div`

`;

const Gradient = styled.div`
  img {
    display: block;
  }
`;

const CustomContainer = styled(Container)`
  padding: 60px 0;
  ${TextBlock} {
    grid-column: 2 / span 8;
    ${Message} {
      margin-bottom: 10px;
    }
  }
  ${FormBlock} {
    grid-column: 2 / span 8;
    grid-row: 1;
    margin-bottom: 60px;
  }
  @media(min-width: ${breakpoints.lg}) {
    padding: 0;
    grid-template-rows: 150px auto auto auto;
    ${FormBlock} {
      grid-row: 2 / span 2;
      grid-column: 2 / span 4;
    }
    ${TextBlock} {
      grid-row: 2 / span 2;
      grid-column: 7 / span 3;
      margin-bottom: 0;
    }
    ${Gradient} {
      grid-row: 3 / span 2;
      grid-column: 7 / span 4;
      align-self: flex-end;
    }
  }
`;

export default (
  {
    pageData,
    rawData,
    ...props
  }) => (
  <CustomContainer {...props}>
    <TextBlock>
      <Message>{pageData.donate_message}</Message>
      <Brief>{RichText.render(rawData.donate_brief, linkResolver)}</Brief>
    </TextBlock>
    <FormBlock>
      <DonationForm/>
    </FormBlock>
    <Gradient>
      <img src={GradientImg}/>
    </Gradient>
  </CustomContainer>
);
