import React from 'react';
import styled from 'styled-components';
import { rtypeN16r, rtypeT21sb, rtypeT38sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { rgba } from 'polished';
import { PurpleOutlineButtonStyle } from '../buttons';

const Title = styled.div`
  ${rtypeT21sb};
  background: ${colors.purple};
  color: ${colors.white};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 30px;
  text-align: center;
`;

const Body = styled.div`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0 30px;
  min-height: 400px;
  max-height: 400px;
  overflow-y: scroll;
  ${rtypeN16r};
  .BBFormContainer {
    padding: 0;
    .BBFormSectionHeading {
      ${rtypeT38sb};
    }
    .BBFormSection.BBDFormSectionComments {
      
      .BBFormFieldLabel.BBFormFieldLabelEdit,
      .BBFormTextArea {
        margin-left: 0;
      }
    }
    input[type=submit] {
      ${PurpleOutlineButtonStyle};
    }
  }
`;


const Container = styled.div`
  box-shadow: 0 2px 8px ${rgba(colors.darkBlue, 0.25)};
`;


export default class DonationForm extends React.Component {
  componentDidMount() {
    window.bboxInit = function () {
      window.bbox.showForm('97d8a826-3d75-4116-9b7e-f305f39235a3');
    };
    (function () {
      var e = document.createElement('script'); e.async = true;
      e.src = 'https://bbox.blackbaudhosting.com/webforms/bbox-min.js';
      document.getElementsByTagName('head')[0].appendChild(e);
    } ());
  }

  render() {
    return (
      <Container>
        <Title>Donation Form</Title>
        <Body>
          <div id="bbox-root"/>
        </Body>
      </Container>
    )
  }
}