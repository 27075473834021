import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import TextCombo from '../shared/TextCombo';
import { linkResolver } from '../../resolvers';
import {RichText} from 'prismic-reactjs';
import { rtypeT38sb } from '../../styles/typography/presets';

const Title = styled.div`
  ${rtypeT38sb};
`;

const Way = styled.li`

`;

const Ways = styled.ul`

`;

const CustomContainer = styled(Container)`
  padding: 60px 0;
  ${Title} {
    grid-column: 2 / span 8;
    margin-bottom: 30px;
  }
  ${Ways} {
    grid-column: 2 / span 8;
    ${Way} {
      max-width: 400px;
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  @media(min-width: ${breakpoints.md}) {
    padding: 120px 0;
    ${Title} {
      margin-bottom: 90px;
    }
    ${Ways} {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 75px;
    }
  }
`;

export default ({pageData, rawData, ...props}) => (
  <CustomContainer>
    <Title>{rawData.other_ways_title}</Title>
    <Ways>
      {rawData.other_ways.map((way, index) => (
        <Way key={way.title}>
          <TextCombo
            title={(index+1).toString().padStart(2, '0')}
            message={way.title}
            brief={RichText.render(way.brief, linkResolver)}
          />
        </Way>
      ))}
    </Ways>
  </CustomContainer>
)