import React from 'react';
import styled from 'styled-components';
import RawHTML from './RawHTML';
import { typeN14r, typeN16r, typeT16sb, typeT21sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { breakpoints } from '../../styles/layout';

const Title = styled.div`
  ${typeN14r};
  color: ${colors.orange};
`;

const Message = styled.div`
  ${typeT16sb};
  
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -27px;
    width: 1px;
    height: 90px;
    background: ${colors.grey};
  }
`;

const Brief = styled.div`
  ${typeN14r};
`;

const Button = styled.div``;

const Container = styled.div`
  padding-left: 27px;
  
  ${Title} {
    margin-bottom: 9px;
  }
  
  ${Button} {
    margin-top: 30px;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    ${Title} {
      ${typeN16r};
    }
    ${Message} {
      ${typeT21sb};
    }
    ${Brief} {
      ${typeN16r};
    }
    ${Button} {
      margin-top: 42px;
    }
  }
`;

const TextCombo = ({ title, message, brief, briefHtml, button, ...props }) => (
  <Container {...props}>
    {title && <Title>{title}</Title>}
    <Message>{message}</Message>
    <Brief>
      {briefHtml && <RawHTML>{briefHtml}</RawHTML>}
      {brief}
    </Brief>
    {button && (
      <Button>
        {button}
      </Button>
    )}
  </Container>
);

export default TextCombo;