import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { rtypeN18r, typeN14r } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { Link } from 'gatsby';
import { WhiteOutlineButtonLink } from '../buttons';


const FootnoteMessage = styled.div`
  ${rtypeN18r};
  color: ${colors.white};
`;

const FootnoteArrow = styled.span``;

const FootnoteArrowLink = styled(Link)`
  ${typeN14r};
  color: ${colors.white};
  line-height: 1em;
  display: inline-block;
  width: 36px;
  height: 36px;
  border: 1px solid ${colors.white};
  border-radius: 100%;
  background: none;
  -webkit-appearance: none;
  padding: 0;
  position: relative;
  
  ${FootnoteArrow} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
  }
`;

const FootnoteButton = styled(WhiteOutlineButtonLink)``;


const FootnoteContainer = styled(Container)`
  align-items: stretch;
  
  &:before {
    background: linear-gradient(to top right, ${colors.yellow}, ${colors.orange});
    content: '';
    border-top-right-radius: 6px;
    grid-row: 1 / span 1;
    grid-column: 1 / span 9;
  }
  
  ${FootnoteMessage} {
    grid-row: 1 / span 1;
    grid-column: 2 / span 5;
    padding: 21px 0;
  }
  
  ${FootnoteButton} {
    grid-column: 8 / span 1;
    grid-row: 1 / span 1;
    align-self: center;
    display: none;
  }
  
  ${FootnoteArrowLink} {
    grid-column: 8 / span 1;
    grid-row: 1 / span 1;
    align-self: center;
  }
  
  @media(min-width: ${breakpoints.ll}) {
    &:before {
      grid-column: 1 / span 6;
    }
    
    ${FootnoteMessage} {
      padding: 26px 0;
    }
    
    ${FootnoteButton}, ${FootnoteArrowLink} {
      grid-column: 5 / span 2;
      justify-self: center;
    }
  }
  
  @media(min-width: ${breakpoints.ll}) {
    ${FootnoteButton} {
      display: inline-block;
    }
    ${FootnoteArrowLink} {
      display: none;
    }
  }
`;


const Footnote = (props) => {
  const {
    message,
    buttonText,
    to,
    className
  } = props;

  return (
    <FootnoteContainer className={className}>
      <FootnoteMessage>{message}</FootnoteMessage>
      <FootnoteButton to={to}>{buttonText}</FootnoteButton>
      <FootnoteArrowLink to={to}>
        <FootnoteArrow>→</FootnoteArrow>
      </FootnoteArrowLink>
    </FootnoteContainer>
  )
};

export default Footnote;