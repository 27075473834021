import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../resolvers';
import Img from 'gatsby-image';
import TextCombo from '../shared/TextCombo';
import { colors } from '../../styles/colors';
import FluidImg from '../shared/FluidImg';

const CustomNumberedTextCombo = styled(TextCombo)`

`;

const Image = styled.div`
`;

const Background = styled.div`
  background: ${colors.orangishGrey};
`;

const CustomContainer = styled(Container)`
  grid-template-rows: 60px auto auto 60px;
  ${Background} {
    grid-row: 1 / span 4;
    grid-column: 1 / span 10;
  }
  ${Image} {
    grid-row: 1 / span 2;
    grid-column: 2 / span 8;
    overflow: hidden;
    margin-top: 30px;
    img {
      border-radius: 6px;
    }
  }
  ${CustomNumberedTextCombo} {
    grid-row: 3 / span 1;
    grid-column: 2 / span 8;
    margin-top: 60px;
    max-width: 400px;
  }
  @media(min-width: ${breakpoints.lg}) {
    grid-template-rows: 60px auto 120px;
    margin-bottom: -120px;
    ${Background} {
      grid-row: 1 / span 2;
    }
    ${Image} {
      grid-row: 2 / span 2;
      grid-column: 6 / span 5;
      margin-top: 0;
      img {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    ${CustomNumberedTextCombo} {
      margin: 153px 0;
      grid-row: 1 / span 2;
      grid-column: 2 / span 3;
      align-self: center;
    }
  }
`;

export default ({ pageData, rawData, ...props }) => (
  <CustomContainer>
    <Background/>
    <CustomNumberedTextCombo
      message={pageData.how_message}
      brief={RichText.render(rawData.how_brief, linkResolver)}
    />
    <Image>
      <FluidImg
        image={pageData.how_image}
      />
    </Image>
  </CustomContainer>
)